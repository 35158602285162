/* All */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.5em;
  background-color: #3A4449;
  color: rgb(234, 233, 233);
  line-height: 1.1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4 {
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: bold;
  color: #191d1f;
}


a {
  text-decoration: none;
  color: #247099;
}

.current {
  font-weight: bold;
  color: #247099;
}

/* gray line with blue light */

.grayline {
  height: 4px;
  background-color: #191b1b;
  padding: 0;
  margin: 0;

}



/* header */

.header {
  background-color: black;
  clear: both;
  min-height: 130px;
  margin-top: 0px;

  position: relative;
}

.logo-width {
  max-width: 200px;
}

.logo {
  width: 200px;
  padding: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}

.logo img {
  border: none;
}

.paypalme {
  position: absolute;
  top: 50px;
  right: 100px;
  z-index: 100;
}




/* header navigation */

nav {
  position: absolute;
  top: 50px;
  right: 230px;
  width: 800px;
  text-align: right;
}

nav ul li {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: .7em;
}

.phone {
  position: absolute;
  top: 52px;
  right: 46px;
  z-index: 100;
}

.phone a {
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
}



/* display */

.display {
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin-top: 34px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.display-box {
  width: 33%;
  border: none;
  background-color: #2D3538;
  background-image: url("./assets/images/displayboxbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.display-box img {
  width: 100%;
  border: none;
}

.box-label {
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 10px;
  background-color: black;
  font-family: 'Barlow Condensed', sans-serif;
  color: #247099;
}




/* main */

section {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding: 40px;
  min-height: 400px;
  max-width: 1000px;
}

section p {
  padding-top: 20px;
  font-size: .8em;
}

section h2 {
  font-size: 1.1em;
  margin-top: 60px;
}

.outer {
  min-height: 100px;
}

.contact-section {
  min-height: 0px;
  padding-bottom: 0px;
}

.call-text {
  margin-top: 40px;
  margin-bottom: 40px;
}

.call-text a {
  color: hsl(195, 10%, 8%);
  text-decoration: underline;
  font-weight: bold;
}

.phone-number {
  font-weight: bold;
}

.mainh1 {
  line-height: 1.1em;
}


/* services */

.service-box {
  text-align: left;
  margin-bottom: 40px;
}

.service-box h2:first-child {
  margin-top: 40px;
}

.service-list {
  margin-top: 20px;
  margin-bottom: 50px;
}

.service-list {
  list-style-type: none;
  font-size: .7em;
}

.service-list li {
  padding-top: 2px;
  padding-bottom: 2px;
}


/* contact form */

.contact-form {
  width: 80%;
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #222527;
  text-align: right;
  padding-right: 40px;
  padding-left: 40px;
  text-align: center;
}

.contact-form input {
  width: 100%;
  padding: 10px;
  border: none;
  font-size: .8em;
  color: #247099;
  text-align: left;
  margin-bottom: 10px;
  background-color: black;
  font-family: 'Roboto', sans-serif;
}

.contact-form input:focus {
  outline: none;
  background-color: hsl(200, 10%, 11%);
}

.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  font-size: .8em;
  color: #247099;
  text-align: left;
  height: 100px;
  background-color: black;
  font-family: 'Roboto', sans-serif;
}

.contact-form textarea:focus {
  outline: none;
  background-color: hsl(200, 10%, 11%);
}

.submit-button {
  width: 150px;
  padding: 10px;
  border: none;
  font-size: .8em;
  color: #247099;
  background-color: black;
  margin-top: 20px;
}

.recaptcha {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.g-recaptcha div:first-child {
  margin: auto;
}




/* gallery */

.gallery {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.gallery-pic {
  width: 300px;
  margin-right: 10px;
  margin-bottom: 30px;
}

.gallery-pic img {
  width: 320px;
}

.gallery2 {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.gallery-pic2 {
  width: 300px;
  margin-right: 10px;
  margin-bottom: 30px;
}

.gallery-pic2 img {
  width: 300px;
}

.spacer {
  height: 200px;
}

.project-img {
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.illustration {
  text-align: center;
}

.illustration img {
  margin-top: 20px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.project1 {
  padding: 40px;
}

.project2 {
  padding: 40px;
}

.project3 {
  padding: 40px;
}




/* footer */

footer {
  min-height: 300px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-content: space-between;
  font-size: .8em;
}

footer .footer-box {
  width: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
}

footer p {
  padding-top: 5px;
}

footer h2 {
  color: #247099;
}

/* screens less than 1000px */
@media screen and (max-width: 1000px) {

  .phone {
    right: 30px;
  }

  nav {
    right: 210px;
  }

}

/* screens less than 858 px */

@media screen and (max-width: 858px) {

  header {
    height: 140px;
  }

  .display {
    margin-top: 15px;
  }

  .logo {
    width: 170px;
    top: 10px;
    left: 10px;
  }

  .logo img {
    width: 170px;
  }

  nav {
    top: 102px;
    left: 0px;
    width: 100%;
    height: 38px;
    padding-top: 2px;
    text-align: center;
    background-color: #141516;
  }

  nav ul li a {
    font-size: .6em;
  }

  .phone {
    font-size: .7em;
    font-weight: bold;
    top: 40px;
  }

  section p {
    font-size: .8em;
  }
}



/* screens less than 680 px */
@media screen and (max-width: 680px) {

  .box-label {
    font-size: .8em;
  }

}


/* screens less than 600px */
@media screen and (max-width: 600px) {

  .display-box {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .display {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .box-label {
    min-height: 30px;
    font-size: 1.2em;
  }

  .contact-form {
    width: 100%;
  }

}



/* screens less than 546px */

@media screen and (max-width: 546px) {}



/* screens less than 368 */

@media screen and (max-width: 368px) {

  nav {
    top: 90px;
    left: 0px;
    width: 100%;
    height: 130px;
    padding-top: 2px;
    text-align: center;
    background-color: #141516;
  }

  nav ul li {
    display: block;
  }

  section {
    margin-top: 100px;
    padding-top: 0px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .home {
    margin-top: 10px;
  }

  .display {
    margin-top: 90px;
  }


}